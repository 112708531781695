import styled, { keyframes, css } from "styled-components";
import { FlexRowCenteredDiv } from "../../styles";

const fadeAnimation = keyframes`
 0% { opacity: 0; }
 33% { opacity: 1; }
 67% { opacity: 1; }
 100% { opacity: 0; }
`;

const fadeAnimationArray = [
  keyframes`
    0% { opacity: 1; }
    50% { opacity: 1; }
  `,
  keyframes`
    0% { opacity: 0; }
    67% { opacity: 1; }
  `,
  keyframes`
    0% { opacity: 0; }
    67% { opacity: 1; }
  `,
  keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `,
];

export const LandingHero = styled('div')`
  height: ${(props) => {
    console.log(props)
    return props.mobile ? `calc(100vh - ${props.mainHero ? '300px' : '150px'})` : `calc(100vh - ${props.mainHero ? '50px' : '350px'})`
  }};
  width: 100%;
  padding: ${(props) => props.mobile ? '150px 0' : props.mainHero ? '0' : '150px 0'};
  background-image: url(${(props) => `${props.image}`});
  background-size: cover;
  background-position-x: 20%;
  background-position-y: 50%;
  position: sticky;
  top: 50px;
  opacity: 1;

  will-change: opacity;
  animation: ${(props) => fadeAnimationArray[props.index]};
  animation-delay: calc((var(--scroll) * -3.4s) + ${(props) => (props.index - 1)}s);
  animation-play-state: paused;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`

export const LandingSubtitleBar = styled(FlexRowCenteredDiv)(props => ({
  width: '95vw',
  maxWidth: '650px',
  marginBottom: '30px',
  marginTop: '90px',
  fontSize: props.mobile ? '0.75em' : '1em',
  margin: '90px auto auto',
}));

LandingSubtitleBar.defaultProps = {
  mobile: false,
}




